import { acceptHMRUpdate, defineStore } from "pinia";

export const useContent = defineStore("content", {
  state: () => {
    const config = useRuntimeConfig();

    return {
      apiBase: config.public.apiBase,
      cache: config.public.cache,
      footerMenu: null,
    };
  },
  getters: {
    createApiUrl: (state) => (url) => {
      return `${state.apiBase}${url}`;
      // return `${state.apiBase}${url}${
      //     state.cache ? "" : "&" + new Date().getTime()
      // }`;
    },
  },
  actions: {
    async fetchFooter() {
      const footerMenu = await fetch(
        this.createApiUrl(`/api/footer?populate=deep,3`)
      )
        .then((response) => response.json())
        .then((result) => result.data)
        .catch((error) => console.log("error", error));
      if (Object.values(footerMenu).length) {
        this.footerMenu = footerMenu;
      } else {
        console.log("FooterMenu is empty");
      }
    },
    async fetchPage(id) {
      const documents = await fetch(
          this.createApiUrl(`/api/pages/${id}?populate=deep`)
      )
          .then((response) => response.json())
          .then((result) => result.data)
          .catch((error) => console.log("error", error));
      if (Object.values(documents).length) {
        return documents;
      } else {
        console.log("Documents empty");
      }
    },
    async fetchDocuments() {
      const documents = await fetch(
        this.createApiUrl(`/api/pages/3?populate=deep`)
      )
        .then((response) => response.json())
        .then((result) => result.data)
        .catch((error) => console.log("error", error));
      if (Object.values(documents).length) {
        return documents;
      } else {
        console.log("Documents empty");
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContent, import.meta.hot));
}
